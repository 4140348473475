import React, { useEffect } from "react";

import { ZoomMtg } from "@zoom/meetingsdk";
import "./App.css";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

//=======================================================
//local url
//https://localhost:3000/?&projectDbName=www&env=local&enrollmentId=RSIBLU55N1VY&userName=Shivani Bansal&projectTitle=Think%20With%20Tabish&classTitle=Encrypted v-2 Class&meetingNumber=8462019215&meetingPassWord=5VuxjP&userEmail=abhay@gamil.com&platform=android&zoomAccountNumber=1&userAuthToken=eyJhbGciOiJSUzI1NiIsImtpZCI6ImJhNjI1OTZmNTJmNTJlZDQ0MDQ5Mzk2YmU3ZGYzNGQyYzY0ZjQ1M2UiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vamVlLXBhcnRuZXJzLWIxIiwiYXVkIjoiamVlLXBhcnRuZXJzLWIxIiwiYXV0aF90aW1lIjoxNzExMjYyMzkwLCJ1c2VyX2lkIjoic1VBaG42TE1VRUFzS3BwY0dFSUQ1Z2NDNE9NayIsInN1YiI6InNVQWhuNkxNVUVBc0twcGNHRUlENWdjQzRPTWsiLCJpYXQiOjE3MTEyODIwMDUsImV4cCI6MTcxMTI4NTYwNSwicGhvbmVfbnVtYmVyIjoiKzkxNzMwNDc4OTg5OSIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzkxNzMwNDc4OTg5OSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.xbHFCbAenOwwHpmwXFMSE5wZSQnjox3npUFsxkHRVexeOq4xj2wf6z88WOchfgUX08_srBogVOExR6ES049Vac05MvO9G8as0I5vtV_6ugN-3xdFVPUIthbYlttyfn6SwtFVFRrYcDIqVFIiu9cFw2USrKFCveQxTQTTDEpl9M25-DaLr1dnitXp-WtOUIPw3HIz7-YcR4W68OqasNWMyQTVuf9Ta700Squxj1A_FrjOgHV2CwxwusHrDygHpE-9neWJkKh-QCu5eQEDRxs9rEqf24nT58aY87vT8CYGW6KZBt46aBhk9qkjhAaPj9UVMnvpjGG6bd2VKgflD_hMlQ
//dev url
//https://techhand-zoom-meeting-sdk.vercel.app/?&projectDbName=www&env=dev&enrollmentId=RSIBLU55N1VY&userName=Shivani Bansal&projectTitle=Think%20With%20Tabish&classTitle=Encrypted v-2 Class&meetingNumber=8462019215&meetingPassWord=5VuxjP&userEmail=abhay@gamil.com&platform=android&zoomAccountNumber=1&userAuthToken=eyJhbGciOiJSUzI1NiIsImtpZCI6ImJhNjI1OTZmNTJmNTJlZDQ0MDQ5Mzk2YmU3ZGYzNGQyYzY0ZjQ1M2UiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vamVlLXBhcnRuZXJzLWIxIiwiYXVkIjoiamVlLXBhcnRuZXJzLWIxIiwiYXV0aF90aW1lIjoxNzExMjYyMzkwLCJ1c2VyX2lkIjoic1VBaG42TE1VRUFzS3BwY0dFSUQ1Z2NDNE9NayIsInN1YiI6InNVQWhuNkxNVUVBc0twcGNHRUlENWdjQzRPTWsiLCJpYXQiOjE3MTEyODIwMDUsImV4cCI6MTcxMTI4NTYwNSwicGhvbmVfbnVtYmVyIjoiKzkxNzMwNDc4OTg5OSIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzkxNzMwNDc4OTg5OSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.xbHFCbAenOwwHpmwXFMSE5wZSQnjox3npUFsxkHRVexeOq4xj2wf6z88WOchfgUX08_srBogVOExR6ES049Vac05MvO9G8as0I5vtV_6ugN-3xdFVPUIthbYlttyfn6SwtFVFRrYcDIqVFIiu9cFw2USrKFCveQxTQTTDEpl9M25-DaLr1dnitXp-WtOUIPw3HIz7-YcR4W68OqasNWMyQTVuf9Ta700Squxj1A_FrjOgHV2CwxwusHrDygHpE-9neWJkKh-QCu5eQEDRxs9rEqf24nT58aY87vT8CYGW6KZBt46aBhk9qkjhAaPj9UVMnvpjGG6bd2VKgflD_hMlQ
//=======================================================

function App() {
    const [params, setParams] = React.useState({});
    const [startingUrl, setStartingUrl] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setStartingUrl(window.location.href);
        const query = new URLSearchParams(window.location.search);

        const params = {};
        for (let param of query.entries()) {
            params[param[0]] = param[1];
        }
        setParams(params);
    }, []);

    const {
        projectTitle,
        classTitle,
        meetingNumber,
        meetingPassWord,
        userName,
        registrantToken = "",
        userEmail,
        platform,
        enrollmentId,
        zoomAccountNumber = "1",
        userAuthToken,
        projectDbName,
        env,
    } = params;

    function getSignature(e) {
        e.preventDefault();

        if (
            !meetingNumber ||
            !enrollmentId ||
            !platform ||
            !userAuthToken ||
            !projectDbName ||
            !zoomAccountNumber ||
            !env
        ) {
            return alert("Invalid meeting data. Please contact the admin.");
        }

        const makeEndPoint = () => {
            if (env === "local") {
                return `https://716d-102-117-233-14.ngrok-free.app/${projectDbName}/zoom/meeting`;
            }
            if (env === "dev") {
                return `https://lecturefiles.dev.api.enap.in/${projectDbName}/zoom/meeting`;
            }
            return `https://lecturefiles.api.enap.in/${projectDbName}/zoom/meeting`;
        };

        const reqBody = {
            meetingId: meetingNumber,
            enrollmentId: enrollmentId,
            platform: platform,
            zoomAccountNumber: zoomAccountNumber,
        };

        const authEndpoint = makeEndPoint();
        setLoading(true);
        fetch(authEndpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: userAuthToken,
            },
            body: JSON.stringify(reqBody),
        })
            .then((res) => res.json())
            .then((response) => {
                const { signature, sdkKey, apiSuccess, message } = response;
                if (!apiSuccess) {
                    return alert(message ?? "Something went wrong!");
                }
                if (!signature || !sdkKey) {
                    return alert(
                        "Unable to join the meeting. Please try again."
                    );
                }
                startMeeting(signature, sdkKey);
            })
            .catch((error) => {
                console.error(error);
                return alert("Something went wrong, please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function startMeeting(signature, sdkKey) {
        document.getElementById("zmmtg-root").style.display = "block";

        ZoomMtg.init({
            patchJsMedia: true,
            leaveUrl: startingUrl,
            success: (success) => {
                console.log(success);
                ZoomMtg.join({
                    signature: signature,
                    sdkKey: sdkKey,
                    meetingNumber: meetingNumber,
                    passWord: meetingPassWord,
                    userName: userName,
                    userEmail: userEmail,
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log(error);
                    },
                    ...(!!registrantToken ? { tk: registrantToken } : {}),
                });
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    if (!meetingNumber) {
        return (
            <div className="App">
                <main>
                    <h1>Zoom Meeting</h1>
                    <h3>Meeting not found, contact support!</h3>
                </main>
            </div>
        );
    }
    return (
        <div className="App">
            <main>
                <h2>{`${projectTitle} Zoom Meeting`}</h2>
                <h3>{classTitle}</h3>
                <button
                    onClick={loading ? null : getSignature}
                    disabled={loading}
                >
                    {loading ? "Joining..." : "Join Meeting Now"}
                </button>
            </main>
        </div>
    );
}

export default App;
